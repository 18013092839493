/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../plugins/themify-icons/themify-icons.css"
import "../plugins/bootstrap-4.5.0-dist/css/bootstrap.min.css"
import "../plugins/uikit/css/uikit.min.css"
import "../plugins/uikit/css/uikit-overwrite.css"
import "./layout.css"
import FooterCA from "./footer-ca"
import FooterCT from "./footer-ct"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {children}
      <div>
        <main></main>
        <FooterCA></FooterCA>
        <FooterCT></FooterCT>
        
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
