import React from "react"

const FooterCA = () => (
  <div class="container-fluid mx-auto">
    <div class="row justify-content-center top-part">
        <div class="col-md-6 text-center center-content">
            <div class="d-flex-inline">
                <h3 class="footer-heading">Faça seu projeto com a Hermes</h3>
            </div>
            <div class="d-flex-inline">
                <p>Queremos saber mais sobre seu projeto. Entre em contato e se surpreenda com a qualidade dos serviços.</p>
                <p>Escolha o melhor para sua empresa. Escolha a Hermes</p>
            </div>
            <div class="d-flex-inline pt-2"> <a href="https://api.whatsapp.com/send?phone=5549936182719" target="_blank" class="btn-primary btn">FALE CONOSCO</a> </div>
        </div>
    </div>
  </div>
)

export default FooterCA