import React from "react"

const FooterCT = () => (
  <div class="container-fluid pb-0 mb-0 justify-content-center text-light ">
    <footer>
        <div class="row my-5 justify-content-center py-5">
          <div class="col-11">
            <div class="row ">
              <div class="col-xl-6 col-md-4 col-sm-4 col-12 my-auto mx-auto a">
                <h3 class="text-muted mb-md-0 mb-5 bold-text">Hermes soluções em <br/> automação industrial.</h3>
              </div>
              <div class="col-xl-3 col-md-4 col-sm-4 col-12">
                <h6 class="mb-3 mb-lg-4 bold-text "><b>MENU</b></h6>
                <ul class="list-unstyled">
                  <li><a href="#">Inicio</a></li>
                  <li><a href="#sobre">Sobre</a></li>
                  <li><a href="#servicos">Serviços</a></li>
                  <li><a href="#solucoes">Soluções</a></li>
                </ul>
              </div>
              <div class="col-xl-3 col-md-4 col-sm-4 col-12">
                  <h6 class="mb-3 mb-lg-4 text-muted bold-text mt-sm-0 mt-5"><b>Endereço</b></h6>
                  <p class="mb-1">Chapecó-SC, Brasil</p>
              </div>
          </div>
          <div class="row ">
              <div class="col-xl-6 col-md-4 col-sm-4 col-auto my-md-0 mt-5 order-sm-1 order-3 align-self-end">
                  <small class="rights"><span>&#174;</span> Hermes desenvolvimento de software LTDA.</small>
              </div>
              <div class="col-xl-3 col-md-4 col-sm-4 col-auto order-1 align-self-end ">
                  <h6 class="mt-55 mt-2 text-muted bold-text"><b>Juliano Hermes</b></h6>
                  <small><span><i class="ti-mobile" aria-hidden="true"></i></span><a href="https://api.whatsapp.com/send?phone=5549936182719" target="_blank">+55 (49) 9 3618-2719</a></small><br/>
                  <small><span><i class="ti-email" aria-hidden="true"></i></span> juliano.hermes@hermes.ind.br</small>
              </div>
              <div class="col-xl-3 col-md-4 col-sm-4 col-auto order-2 align-self-end mt-3 ">
                  <h6 class="text-muted bold-text"><b>Setor Vendas</b></h6>
                  <small><span><i class="ti-email" aria-hidden="true"></i></span> comercial@hermes.ind.br</small>
              </div>
            </div>
          </div>
      </div>
    </footer>
  </div>
)

export default FooterCT